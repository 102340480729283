import {
    ChainId,
    Env,
    EnvLayerToChainId,
    Layer,
    SWITCH_NETWORK_CONFIG,
} from '../constants';
import { useAppSelector } from 'state/hooks';
import AlertFunc from 'utils/components/alert';
import { useSwitchChain } from 'wagmi';

function handleError(e: any) {
    console.error(e);
    let message;
    if ((e as any).code === 4001) {
        return;
    }
    if ((e as any).code === -32002) {
        message = 'Please approve the chain change request in your wallet';
    }

    AlertFunc({
        type: 'error',
        duration: 10000,
        text:
            message ||
            (e as any).message ||
            'Something wrong, please try again later',
    });
}

export default function useWeb3SwitchNetwork() {
    const { chainId, address, isPolis, web3 } = useAppSelector(
        (state) => state.user,
    );
    const { switchChainAsync } = useSwitchChain();
    function handleChangeNetwork(cid: ChainId) {
        return new Promise(async (resolve) => {
            if (isPolis) {
                resolve(true);
                return;
            }

            const res = await switchChainAsync({
                chainId: cid,
            });
            if (res.id === cid) {
                resolve(true);
            } else {
                resolve(false);
            }
        });
    }

    async function handleChangeNetworkByEnvAndLayer(env: Env, layer: Layer) {
        const switchChainId = EnvLayerToChainId[env][layer];

        const res = await handleChangeNetwork(Number(switchChainId));

        return res;
    }

    return {
        handleChangeNetworkByEnvAndLayer,
    };
}
