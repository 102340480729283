import React, { useState, useEffect, useRef, useMemo } from 'react';
import { TokenConfig, Token, Layer, Env } from '../../constants';

import { Container, InputLabel, Input, List } from '@mui/material';

import TokenInfo from './TokenInfo';
import GoBackImg from 'assets/images/go_back.png';
import Modal from 'components/Modal';
import { useAppSelector } from 'state/hooks';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

interface Props {
    isOpen: boolean;
    onClose(): void;
    layer: Layer;
    onSelectToken(token: Token): void;
}

const StyledContainer = styled(Container)`
    width: 464px;
    height: 682px;
    max-height: 90vh;
    margin: 0 auto;
    border-radius: 16px;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `}
    .goBackBtn {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 22px;
        top: 24px;
        padding: 0;
        border: none;
        cursor: pointer;
    }

    .tokenForm {
        display: flex;
        flex-flow: column wrap;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 24px;
    }

    .tokenLabel {
        font-size: 20px;
        font-weight: 600;
        color: ${({ theme }) => theme.c.t01};
        line-height: 24px;
        margin-bottom: 14px;
        text-align: center;
    }

    .tokenList {
        flex-grow: 1;
        height: calc(100% - 100px);
        max-height: calc(90vh - 100px);
        .tokenItems {
            height: 100%;
            overflow: auto;
            padding: 0;
        }
    }
`;

const StyledInput = styled(Input)`
    width: 100%;

    .MuiInputBase-input {
        height: 56px;
        padding: 0 16px;

        background: ${({ theme }) => theme.c.bg04};

        border-radius: 40px;
        border: 0;

        color: ${({ theme }) => theme.c.t01};
        ::placeholder {
            color: ${({ theme }) => theme.c.t02};
        }

        ${({ theme }) => theme.mediaWidth.upToSmall`
          width: 100%;
          padding: 0 16px;
        `}
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.87);
    }
`;

export default function TokenListFunc({
    isOpen,
    onClose,
    layer,
    onSelectToken,
}: Props) {
    const [searchName, setSearchName] = useState<string>('');
    const chooseInput = useRef<HTMLInputElement>();
    const { tokenList: tokens } = useAppSelector((state) => state.tokens);
    const { env } = useAppSelector((state) => state.metisMiddlewareClient);

    const handleInput = (value: string) => {
        setSearchName(value);
    };

    const tokenlist = useMemo(() => {
        if (
            (layer === Layer.layer1 &&
                searchName.toLowerCase() ===
                    TokenConfig[Token.wow][Layer.layer1][
                        Env.mainnet
                    ].address.toLowerCase()) ||
            (layer === Layer.layer2 &&
                searchName.toLowerCase() ===
                    TokenConfig[Token.wow][Layer.layer2][
                        Env.mainnet
                    ].address.toLowerCase())
        ) {
            return [Token.wow];
        }

        const searchReg = new RegExp(searchName.toLowerCase().trim());

        return tokens.filter((item) => {
            let { name, symbol } = TokenConfig[item][layer];
            name = name.toLowerCase();
            symbol = symbol.toLowerCase();

            // if (env === Env.mainnet) {
            //     return (
            //         item !== Token.eth &&
            //         (searchReg.test(name) || searchReg.test(symbol))
            //     );
            // }

            return (
                (searchReg.test(name) ||
                    searchReg.test(symbol) ||
                    searchName.toLowerCase() ===
                        TokenConfig[item][layer][env].address.toLowerCase()) &&
                item !== Token.wow
            );
        });
    }, [searchName, env, layer, tokens]);

    const isDeposit = useMemo(() => {
        return layer === Layer.layer1;
    }, [layer]);

    const list = useMemo(() => {
        console.log(tokenlist);
        if (isDeposit) {
            return tokenlist.filter((item) => item !== Token.link);
        }

        return tokenlist;
    }, [tokenlist, isDeposit]);
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            // customContentClass={style.content}
        >
            <StyledContainer>
                <div className="tokenForm">
                    <InputLabel className="tokenLabel" htmlFor="name" shrink>
                        Choose a token
                    </InputLabel>
                    <StyledInput
                        id="name"
                        autoFocus={isMobile ? false : true}
                        disableUnderline
                        ref={chooseInput}
                        onKeyUp={(event: any) =>
                            handleInput(event.target.value)
                        }
                        aria-describedby="name-text"
                        placeholder="Search name or paste an address"
                    />
                </div>

                <div className="tokenList">
                    <List className="tokenItems">
                        {list.map((item, idx) => (
                            <TokenInfo
                                key={idx}
                                name={item}
                                layer={layer}
                                onClick={(name: Token) => {
                                    onSelectToken(name);
                                    onClose();
                                }}
                            />
                        ))}
                    </List>
                </div>
            </StyledContainer>
        </Modal>
    );
}
