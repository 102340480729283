import {
    EnvLayerToChainId,
    Layer,
    Token,
    TokenConfig,
    TxActStatus,
} from '../../constants';
import { useGetContractByUserWeb3 } from 'hooks/use-contract';
import { useAppSelector } from 'state/hooks';
import React from 'react';
import Button from '../Button';
// import style from './index.module.scss';
import ERC20_ABI from 'contracts/erc20.json';
import styled from 'styled-components';

const StyledButton = styled(Button)`
    color: #64d9cd !important;
    box-sizing: border-box;
    padding: 6px 12px !important;
    border-radius: 4px;
    cursor: pointer;
    user-select: none;
    font-size: 14px !important;
    background: transparent;
    border: none;
    /* &:hover {
        background-color: #122537;
    } */
    .loadingIcon {
        color: #122537;
        font-size: 20px;
    }
`;

interface Props {
    token: Token;
}

export default function AddToken({ token }: Props) {
    const { client, env, layer } = useAppSelector(
        (state) => state.metisMiddlewareClient,
    );
    const { isPolis, web3 } = useAppSelector((state) => state.user);
    const getContract = useGetContractByUserWeb3();

    async function addToken() {
        const configToken = TokenConfig[token];
        let address = configToken[layer][env].address || '';
        let decimals = 0;
        const img =
            token === Token.metis
                ? `https://bridge.metis.io/logo.png`
                : `https://raw.githubusercontent.com/MetisProtocol/metis-bridge-resources/master/tokens/${configToken[
                      Layer.layer1
                  ].symbol.toUpperCase()}/logo.png`;
        if (isPolis && client) {
            const domainRes = await client.getDomain(
                configToken.domain,
                `${EnvLayerToChainId[env][layer]}`,
            );

            address = domainRes ? domainRes.contract_address : undefined;

            if (!address) return;

            const decimalsRes = await client.sendTxAsync(
                configToken.domain,
                EnvLayerToChainId[env][layer],
                'decimals',
                [],
                true,
            );
            if (decimalsRes && decimalsRes.act === TxActStatus.SUCCESS) {
                const data = Number(decimalsRes.result);
                if (typeof data === 'number') {
                    decimals = data;
                }
            }

            client
                .addTokenToMM({
                    token: configToken[layer].symbol,
                    tokenAddress: address,
                    tokenDecimals: decimals,
                    tokenImage: img,
                    chainId: EnvLayerToChainId[env][layer],
                })
                .then((res) => {
                    console.log(
                        `Successfully added ${configToken[layer].symbol} to MetaMask`,
                    );
                })
                .catch((err) => {
                    console.log('add failed', err);
                });
        } else if (web3 && web3.currentProvider) {
            const contract = getContract(address, ERC20_ABI);

            if (contract) {
                decimals = await contract.methods.decimals().call();
            }

            if (address && decimals) {
                const params: any = {
                    type: 'ERC20',
                    options: {
                        address: address,
                        symbol: configToken[layer].symbol,
                        decimals: decimals,
                        image: img,
                    },
                };
                web3.currentProvider
                    .request({
                        method: 'wallet_watchAsset',
                        params,
                    })
                    .then((success: any) => {
                        console.log(success);
                        if (success) {
                            console.log(
                                `Adding ${configToken[layer].symbol} to MetaMask`,
                            );
                        } else {
                            console.error('Add token faild', success);
                        }
                    })
                    .catch(console.error);
            } else {
                console.error(
                    `token ${configToken[layer].symbol} address or decimals error`,
                );
            }
        }
    }

    return (
        <StyledButton
            onClick={addToken}
            type="transparent"
            showLoading
            loadingProps={{
                // classname: style.loadingIcon,
                size: 24,
            }}
        >
            Add to wallet
        </StyledButton>
    );
}
